

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.card-bg {
  background: linear-gradient(253.99deg, #03023C 8.38%, rgba(3, 2, 60, 0.604952) 33.67%, rgba(3, 2, 60, 0.94) 92.23%);

}

.fadeIn {
  animation-name: fadeIn;
  animation-duration: 1000ms;
}

/* Toggle B */
input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #48bb78;
}


.bg_itw{
  background-position-y: 10%;
}


.cr-input input[type="radio"]:checked + label {font-weight:bold;color: green;border:1px solid blue}